var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container mt-4 mb-7 mt-md-12 mb-md-15 py-0"},[_c('b-breadcrumb',{staticClass:"mb-5 mb-md-6 p-0",staticStyle:{"padding":"0!important"}},[_c('b-breadcrumb-item',{attrs:{"to":{ name: 'LegalInformation' }}},[_vm._v(" "+_vm._s(_vm.$t("Footer.LegalInformation"))+" ")]),_c('b-breadcrumb-item',{staticClass:"text-capitalize",attrs:{"active":""}},[_vm._v(_vm._s(_vm.$route.params.type.replaceAll("-", " ")))])],1),_c('div',{staticClass:"d-flex flex-column flex-md-row gap-3"},[_c('div',{staticClass:"bg-white rounded p-3 p-md-4 px-lg-16 py-lg-10 border border-outline-secondary flex-grow-1"},[_c('HeadingInfo',{attrs:{"heading":_vm.$route.params.type.includes('privacy')
            ? _vm.$t('Legal.PrivacyPolicy')
            : _vm.$t('Legal.TermsOfUse'),"pdfUrl":_vm.baseUrl +
                  'assets/legal/' +
                  (_vm.$route.params.type.includes('privacy')
                    ? 'Privacy_Notice-PashMotors.pdf'
                    : 'Terms_of_Use-PashMotors.pdf'),"description":`
          <span class='text-muted fs-18 family-inter'>${_vm.$t('Legal.LastUpdated', [_vm.lastUpdated])}</span>
          `}}),_c('div',{staticClass:"d-flex justify-content-between gap-3 mt-5 mt-md-6"},[(_vm.$route.params.type.includes('privacy'))?_c('PrivacyPolicy',{on:{"setDate":date => _vm.lastUpdatedDate = date}}):_vm._e(),(_vm.$route.params.type.includes('terms-of-use'))?_c('TermsOfUse',{on:{"setDate":date => _vm.lastUpdatedDate = date}}):_vm._e()],1)],1),_c('div',{staticClass:"d-flex flex-column gap-2"},[_c('div',{staticClass:"p-3 p-md-4 px-lg-16 py-lg-10 rounded border border-outline-secondary bg-white secondary-info"},[_c('h6',{staticClass:"fs-20 fw-600 mb-5 mb-md-7 mb-lg-9"},[_vm._v(" "+_vm._s(_vm.$t("Legal.ContactUs.Desc", [_vm.$route.params.type.includes("privacy") ? _vm.$t("Legal.PrivacyPolicy").toLowerCase() : _vm.$t("Legal.TermsOfUse").toLowerCase()]))+" ")]),_c('div',{staticClass:"mb-5 mb-md-7 mb-lg-9"},[_c('strong',[_c('p',{staticClass:"mb-2 mb-md-3 fw-600 text-muted fs-18"},[_vm._v(_vm._s(_vm.$t("Legal.ByEmail")))])]),_c('a',{staticClass:"d-flex align-items-center",attrs:{"href":"mailto:contact@pashmotors.com"}},[_c('At'),_c('div',[_vm._v(" Contact@pashmotors.com ")])],1)]),_c('div',[_c('strong',[_c('p',{staticClass:"mb-2 mb-md-3 fw-600 text-muted fs-18"},[_vm._v(_vm._s(_vm.$t("Legal.ByMail")))])]),_c('p',{staticClass:"mb-0"},[_vm._v(" Groupe Pash Inc. ")]),_vm._m(0)])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mb-0"},[_vm._v(" 1235 Notre-Dame St W Unit #276, Montreal, QC. "),_c('br'),_vm._v(" H3C 0B1, CA. ")])
}]

export { render, staticRenderFns }