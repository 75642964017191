<template>
  <div class="container mt-4 mb-7 mt-md-12 mb-md-15 py-0">
    <b-breadcrumb class="mb-5 mb-md-6 p-0" style="padding:0!important">
      <b-breadcrumb-item
        :to="{ name: 'LegalInformation' }
        "
      >
        {{
          $t("Footer.LegalInformation")
        }}
      </b-breadcrumb-item>
      <b-breadcrumb-item active class="text-capitalize">{{
        $route.params.type.replaceAll("-", " ")
      }}</b-breadcrumb-item>
    </b-breadcrumb>
    <div class="d-flex flex-column flex-md-row gap-3">
      <div class="bg-white rounded p-3 p-md-4 px-lg-16 py-lg-10 border border-outline-secondary flex-grow-1">
        <HeadingInfo
          :heading="
            $route.params.type.includes('privacy')
              ? $t('Legal.PrivacyPolicy')
              : $t('Legal.TermsOfUse')
          "
          :pdfUrl="
                  baseUrl +
                    'assets/legal/' +
                    ($route.params.type.includes('privacy')
                      ? 'Privacy_Notice-PashMotors.pdf'
                      : 'Terms_of_Use-PashMotors.pdf')
                "
          :description="`
            <span class='text-muted fs-18 family-inter'>${$t('Legal.LastUpdated', [lastUpdated])}</span>
            `
          "
        />
        <div class="d-flex justify-content-between gap-3 mt-5 mt-md-6">
          <PrivacyPolicy v-if="$route.params.type.includes('privacy')" @setDate="date => lastUpdatedDate = date"/>
          <TermsOfUse v-if="$route.params.type.includes('terms-of-use')" @setDate="date => lastUpdatedDate = date"/>
        </div>
      </div>
      <div class="d-flex flex-column gap-2">
        <div class="p-3 p-md-4 px-lg-16 py-lg-10 rounded border border-outline-secondary bg-white secondary-info">
          <h6 class="fs-20 fw-600 mb-5 mb-md-7 mb-lg-9">
            {{ $t("Legal.ContactUs.Desc", [$route.params.type.includes("privacy")
                ? $t("Legal.PrivacyPolicy").toLowerCase()
                : $t("Legal.TermsOfUse").toLowerCase()]) }}
          </h6>
          <div class="mb-5 mb-md-7 mb-lg-9">
            <strong><p class="mb-2 mb-md-3 fw-600 text-muted fs-18">{{ $t("Legal.ByEmail") }}</p></strong>
            <a class="d-flex align-items-center" href="mailto:contact@pashmotors.com">
              <At/>
              <div>
                Contact@pashmotors.com
              </div>
            </a>
          </div>
          <div>
            <strong><p class="mb-2 mb-md-3 fw-600 text-muted fs-18">{{ $t("Legal.ByMail") }}</p></strong>
              <p class="mb-0">
                Groupe Pash Inc.
                </p>
                <p class="mb-0">
                1235 Notre-Dame St W Unit #276, Montreal, QC. <br/> H3C 0B1, CA.
              </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadingInfo from "@/components/global/headings/HeadingInfo.vue";
import PrivacyPolicy from "@/components/legal/PrivacyPolicy.vue";
import TermsOfUse from "@/components/legal/TermsOfUse.vue";

export default {
  components: { HeadingInfo, PrivacyPolicy, TermsOfUse },
  data() {
    return {
      lastUpdatedDate: '',
      baseUrl:
        process.env.NODE_ENV === "development"
          ? "https://localhost:8080/"
          : process.env.VUE_APP_BASE_URL_IMAGE,
    };
  },
  computed: {
    lastUpdated() {
      return this.lastUpdatedDate;
    }
  },
  beforeMount() {
    document.title = `${this.$route.params.type.replaceAll("-", " ")} | ${document.title}`
  }
};
</script>

<style lang="scss" scoped>
</style>
