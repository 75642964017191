<template>
  <div id="__enzuzo-root-tos"></div>
</template>


<script>
export default {
  data() {
    return {
      dateInterval: null
    }
  },
  beforeMount() {
    let enzuzoPP = document.createElement('script')
      enzuzoPP.setAttribute('id', '__enzuzo-root-script')
      enzuzoPP.setAttribute('src', 'https://app.enzuzo.com/__enzuzo-privacy-app.js?mode=tos&apiHost=https://app.enzuzo.com&qt=1683133828004&referral=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDdXN0b21lcklEIjoyMTI4MiwiQ3VzdG9tZXJOYW1lIjoiY3VzdC1IdHVqSjlPUCIsIkN1c3RvbWVyTG9nb1VSTCI6IiIsIlJvbGVzIjpbInJlZmVycmFsIl0sIlByb2R1Y3QiOiJlbnRlcnByaXNlIiwiaXNzIjoiRW56dXpvIEluYy4iLCJuYmYiOjE2ODMxMzE1NDd9.jKbeEOoHFaHf5odzEopgdw1CQWjPMTGw0zXRCQJ-e7E')
      document.head.appendChild(enzuzoPP)
    },
    mounted() {
      this.dateInterval = setInterval(() => {
        if (document.getElementById('ENZUZO_POLICY_FOOTER')) {
        this.$emit('setDate', document.getElementById('ENZUZO_POLICY_FOOTER').innerText.split(":",2)[1])
        clearInterval(this.dateInterval)
      }
    },400)
    },
    beforeDestroy() {
      document.querySelectorAll('style[data-jss]').forEach(
        elem => {
          elem.remove()
        }
      )
      document.getElementById("__enzuzo-root-script").remove()      
      document.getElementById("enzuzo_tos_custom_css").remove()
    },
    destroyed() {
      clearInterval(this.dateInterval);
    }
};
</script>

<style></style>
